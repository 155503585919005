import Image from "next/image";

export const ENV = {
  url: process.env.NEXT_PUBLIC_URL || "https://bikebiz.com.au",
};

export const RESULT_PER_PAGE = 60;
export const GIFTCARD_AMOUNT_LIST = [25, 50, 75, 100, 200];
export const GIFT_CARD_NAME = "Gift Card";
export const GIFT_CARD_SLUG = "gift-card";
export const GIFTCARD_IMAGES = {
  imageBackdrop: "/gift-card/bikebiz-gift-card-backdrop.jpg",
  imageGiftCard: "/gift-card/bikebiz-gift-card.jpg",
  imageCards: "/gift-card/cards-image.png",
};

export const GATEWAY_LIMIT_VALUE = {
  afterPay: {
    min: 0,
    max: 2000,
  },
  zip: {
    min: 0,
    max: 5000,
  },
};

export const BRAINTREE_SUPPORTED_METHODS = [
  "bikebiz.payments.creditCard",
  "bikebiz.payments.paypal",
  // "bikebiz.payments.googlepay",
  "bikebiz.payments.applepay",
];
export const PAYMENT_METHODS_ICON = {
  "plugin.gateway.afterpay": <Image src="/after-pay.svg" width={56} height={10} alt="after-pay" />,
  "bikebiz.payments.zipmoney": <Image src="/zip-pay.svg" width={54} height={22} alt="zip-pay" />,
  "bikebiz.payments.paypal": <Image src="/paypal-pay.svg" width={56} height={19} alt="paypal-pay" />,
  "bikebiz.payments.creditCard": <Image src="/visa-pay.svg" width={56} height={27} alt="visa-pay" />,
  // "bikebiz.payments.googlepay": <Image src="/google-pay.svg" width={46} height={24} alt="google-pay" />,
  "bikebiz.payments.applepay": <Image src="/apple-pay.svg" width={42} height={20} alt="apple-pay" />,
};

export const COUNTRIES_RESTRICTION = [
  {
    country: "Australia",
    code: "AU",
  },
];

export const PHONE_COUNTRIES = ["au"];
export const PHONE_SETTINGS = {
  countries: ["au"],
  masks: { au: ".... ... ..." },
};

export const GTM_CHECKOUT_OPTIONS = {
  viewCart: {
    step: 1,
    option: "View Cart",
    ga4: "view_cart",
  },

  beginCheckout: {
    step: 2,
    option: "Begin Checkout",
    ga4: "begin_checkout",
  },
  shippingMethod: {
    step: 3,
    option: "Shipping Method",
    ga4: "add_shipping_info",
  },
  paymentInfo: {
    step: 4,
    option: "Payment info",
    ga4: "add_payment_info",
  },
};

export const IMAGE_SIZES = {
  PUBLIC: "public",
  THUMBNAIL: "thumbnail",
  MEDIUM: "medium",
  LARGE: "large",
};
