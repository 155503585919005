import { ga4BuildCategories } from "./utils";

export const checkoutViewEvent = async (checkoutLines, actionField, opts = { ga4: true, ga: true }) => {
  if (opts.ga) {
    const products = checkoutLines.map(line => {
      return saleorProductData(line);
    });
    const tagManagerArgs = {
      dataLayer: {
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: {
              step: actionField.step,
              option: actionField.option,
              action: "checkout",
            },
            products: products,
          },
        },
      },
    };
    (await import("react-gtm-module")).default.dataLayer(tagManagerArgs);
  }

  // GA4 start
  if (!opts.ga4 || !actionField.ga4) return;
  const ga4Items = checkoutLines.map(line => {
    return saleorProductData(line, opts.ga4);
  });
  const ga4Data = {
    event: actionField.ga4,
    ecommerce: {
      items: ga4Items,
    },
  };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(ga4Data);
  console.log("==========checkoutViewEvent===ga4Data", ga4Data);
  console.log("==========checkoutViewEvent===window.dataLayer", window.dataLayer);
  // GA4 end
};

export const productViewEvent = async product => {
  const productData = saleorProductDetail(product);
  const price = product.pricing.priceRange;
  const tagManagerArgs = {
    dataLayer: {
      event: "productDetailView",
      ecommerce: {
        currencyCode: price.start.gross.currency,
        detail: {
          products: [productData],
        },
      },
    },
  };

  (await import("react-gtm-module")).default.dataLayer(tagManagerArgs);
  // GA4 start
  const ga4 = true;
  const ga4Item = saleorProductDetail(product, ga4);
  const ga4Data = {
    event: "view_item",
    ecommerce: {
      items: [ga4Item],
    },
  };
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(ga4Data);
  console.log("==========productViewEvent===ga4Data", ga4Data);
  console.log("==========productViewEvent===window.dataLayer", window.dataLayer);
  // GA4 end
};

export const productsImpressionEvent = async (typesenseProducts, list, page, perPage) => {
  const products = typesenseProductList(typesenseProducts, list, page, perPage);
  const tagManagerArgs = {
    dataLayer: {
      event: "productsImpression",
      ecommerce: {
        currencyCode: "AUD",
        impressions: products,
      },
    },
  };
  (await import("react-gtm-module")).default.dataLayer(tagManagerArgs);
  // GA4 start
  const ga4 = true;
  const ga4Items = typesenseProductList(typesenseProducts, list, page, perPage, ga4);
  const ga4Data = {
    event: "view_item_list",
    ecommerce: {
      items: ga4Items,
    },
  };
  if (typeof window !== "undefined") {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(ga4Data);
    console.log("==========productsImpressionEvent===ga4Data", ga4Data);
    console.log("==========productsImpressionEvent===window.dataLayer", window.dataLayer);
  }
  // GA4 end
};

const typesenseProductDetail = (product, position, list, ga4 = false) => {
  const price = product.on_discount ? product.discount_price : product.price;
  let data = {
    id: product.url_key,
    name: product.title,
    price: parseFloat(price).toFixed(2),
    position: position,
    list: list,
  };

  if (product.brand) {
    data["brand"] = product.brand;
  }

  if (product.categories?.length) {
    data["category"] = product.categories.join("/");
  }

  let ga4Item = null;
  if (ga4) {
    let item_list = list;
    ga4Item = {
      item_name: product?.title || "",
      item_id: product?.url_key,
      price: parseFloat(price).toFixed(2) * 1 || 0.0,
      item_brand: product?.brand || "",
      item_variant: "",
      item_list_name: item_list || "",
      item_list_id: item_list || "",
      index: position * 1 || 0,
    };
    const typesense = true;
    ga4BuildCategories(product, ga4Item, typesense);
  }

  return ga4 ? ga4Item : data;
};

const typesenseProductList = (products, list, page = 1, perPage, ga4 = false) => {
  const productListData = products.map(product => {
    const index = products.indexOf(product);
    const position = index + 1 + (page - 1) * perPage;

    const data = typesenseProductDetail(product, position, list, ga4);
    return data;
  });

  return productListData;
};

const saleorProductData = (checkoutLine, ga4 = false) => {
  const { variant, quantity } = checkoutLine;
  const { product } = variant;
  const brand = product.attributes.find(item => item.attribute.name === "Manufacturer");
  const category = categoryPath(product.category);
  let data = {
    id: product.slug,
    name: product.name,
    price: (variant?.pricing?.price?.gross?.amount || 0).toFixed(2),
    quantity: quantity,
    variant: variant.name,
  };

  if (brand) {
    data["brand"] = brand?.values[0]?.name;
  }

  if (category) {
    data["category"] = category;
  }

  if (ga4) {
    let itemId = product?.slug || "";
    let itemName = product?.name || "";
    data = {
      item_name: itemName,
      item_id: itemId,
      price: (variant?.pricing?.price?.gross?.amount || 0).toFixed(2) * 1 || 0.0,
      item_brand: brand?.values[0]?.name || "",
      item_variant: variant?.name || "",
      quantity: quantity * 1 || 0,
    };
    ga4BuildCategories(product, data);
  }

  return data;
};

const saleorProductDetail = (product, ga4 = false) => {
  const category = categoryPath(product.category);
  const brand = product.attributes.find(item => item.attribute.name === "Manufacturer");
  const price = product.pricing?.onSale ? product.pricing.priceRange : product.pricing.priceRangeUndiscounted;

  let data = {
    id: product.slug,
    name: product.name,
    price: price.start.gross.amount.toFixed(2),
  };

  if (brand) {
    data["brand"] = brand?.values[0]?.name;
  }

  if (category) {
    data["category"] = category;
  }

  let ga4Item = null;
  if (ga4) {
    ga4Item = {
      item_name: product?.name || "",
      item_id: product?.slug || "",
      price: price.start.gross.amount.toFixed(2) * 1 || 0.0,
      item_brand: brand?.values[0]?.name || "",
      item_variant: "",
    };
    ga4BuildCategories(product, ga4Item);
  }

  return ga4 ? ga4Item : data;
};

export const categoryPath = category => {
  const { ancestors } = category;
  const list = ancestors?.edges || [];
  if (!list.length) return category?.name;
  if (list.length > 5) {
    const limitCategories = list.slice(Math.max(list.length - 5, 1));
    const categoryNames = limitCategories.map(ed => ed.node.name).concat(category.name);
    return categoryNames.join("/");
  } else {
    const categoryNames = list.map(ed => ed.node.name).concat(category.name);
    return categoryNames.join("/");
  }
};
